
import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { ArrowRight } from "react-bootstrap-icons";
import Navbar from './Navbar';
import EvandroImage from './assets/luiz-evandro.png'
import PauloImage from './assets/paulo-blassioli.png'
import GabrielImage from './assets/gabriel-andreolli.png'
import './styles/talentos.css'
import Elipse from './assets/elipse.png'
import { Footer } from './Footer';

const Talentos = () => {
  return (
    <>
      <Navbar />
      <Container fluid className='section section-directors'>
        <Row>
          <Col className='title-page'>
            <h4>
              Talentos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <img className='elipse elipse-1' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-2' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-3' src={Elipse} />
          </Col>
        </Row>
        <Row className='title-directors-nossos'>
          <Col>
            <h1 className='title-h1-fraunces' >Nossos</h1>
          </Col>
        </Row>
        <Row className='title-directors-diretores'>
          <Col>
            <h1 className='title-h1-fraunces' >Talentos</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={4} xl={4} className='image-diretores director'>
            <a href='/luiz-evandro'><Image src={EvandroImage} fluid /></a>
            <h1 className='title-director'>
              Luiz <br /> Evandro
            </h1>
            <p className='description-director'>
              Diretor Multimídia e Designer, possui uma sensibilidade genuína para a tecnologia.
            </p>
            <span className='mobile'>
              <a className='link-trabalhos-talentos' href="/luiz-evandro" >Veja os trabalhos de Luiz Evandro <ArrowRight size={26} /></a>
            </span>
          </Col>
          <Col xs={{span: 12, order: 'first'}} sm={12} lg={4} xl={4} className='image-diretores director'>
            <a href='/paulo-blassioli'><Image src={PauloImage} fluid /></a>

            <h1 className='title-director'>
              Paulo <br /> Blassioli
            </h1>
            <p className='description-director'>
              Diretor de Cena e Produtor Executivo, um criativo multitarefa apaixonado por desafios e narrativas.
            </p>
            <span className='mobile'>
              <a className='link-trabalhos-talentos' href="/paulo-blassioli" >Veja os trabalhos de Paulo Blassioli <ArrowRight size={26} /></a>
            </span>
          </Col>

          <Col xs={12} sm={12} lg={4} xl={4} className='image-diretores director'>
            <a href='gabriel-andreolli'><Image src={GabrielImage} fluid /></a>

            <h1 className='title-director'>
              Gabriel <br /> Andreolli
            </h1>
            <p className='description-director'>
              Diretor de Cena, que conecta a estética contemporânea à poética para criar filmes marcantes.
            </p>
            <span className='mobile'>
              <a className='link-trabalhos-talentos' href="/gabriel-andreolli" >Veja os trabalhos de Gabriel Andreolli <ArrowRight size={26} /></a>
            </span>
          </Col>
        </Row>

        <Row>
          <Col className='desk links-space-talentos'>
            <span>
              <a className='link-trabalhos-talentos' href="/luiz-evandro" >Veja os trabalhos de Luiz Evandro <ArrowRight size={36} /></a>
            </span>
          </Col>
          <Col className='desk links-space-talentos'>
            <span>
              <a className='link-trabalhos-talentos' href="/paulo-blassioli" >Veja os trabalhos de Paulo Blassioli <ArrowRight size={36} /></a>
            </span>
          </Col>
          <Col className='desk links-space-talentos'>
            <span>
              <a className='link-trabalhos-talentos' href="/gabriel-andreolli" >Veja os trabalhos de Gabriel Andreolli <ArrowRight size={36} /></a>
            </span>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Talentos;
