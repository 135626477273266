import './styles/global.css'
import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './Layout'
import Home from './Home'
import Talentos from './Talentos';
import LuizEvandro from './LuizEvandro';
import PauloBlassioli from './PauloBlassioli';
import GabrielAndreolli from './GabrielAndreolli';
import Contact from './Contact';
import About from './About';
import Movies from './Movies';
import Experience from './Experience';
export default function App () {
  const setVh = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };
  window.addEventListener('resize', setVh);
  setVh();
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="talentos" element={<Talentos />} />
          <Route path="luiz-evandro" element={<LuizEvandro />} />
          <Route path="paulo-blassioli" element={<PauloBlassioli />} />
          <Route path="gabriel-andreolli" element={<GabrielAndreolli />} />
          <Route path="contato" element={<Contact />} />
          <Route path='sobre-nos' element={<About />} />
          <Route path='filmes' element={<Movies />} />
          <Route path='experiencias' element={<Experience />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot( document.getElementById( 'root' ) )
root.render( <App /> )
