import React, { useState } from 'react';
import Navbar from './Navbar';
import { Row, Col, Container, Image, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import ElipseVideo from './assets/reel.mp4';
import Woman from './assets/home.gif'
import Elipse from './assets/elipse.png'
import ImgOne from './assets/section_two_1.png'
import ImgTwo from './assets/section_two_2.png'
import ImgThree from './assets/section_two_3.png'
import NossoTrabalho from './assets/nosso_trabalho.png'
import NossoTrabalhoMob from './assets/nosso_trabalho_mob.png'
import HomemMobile from './assets/homem_mobile.png'
import Homem from './assets/homem.png'
import Predio from './assets/predio.png'
import PredioDois from './assets/predio_dois.png'
import PredioInovacao from './assets/predio_inovacao.png'
import Stephen from './assets/stephen.png'
import BodyImg from './assets/body.png'
import Dress from './assets/dress.png'
import FoodOne from './assets/food_one.png'
import FoodTwo from './assets/food_two.png'
import FoodThree from './assets/food_three.png'
import FoodFour from './assets/food_four.png'
import Button from 'react-bootstrap/Button';
import { Footer } from './Footer';
import './styles/home/1-section.css'
import './styles/home/2-section.css'
import './styles/home/3-section.css'
import './styles/home/4-section.css'
import './styles/home/5-section.css'
import './styles/home/6-section.css'
import './styles/home/7-section.css'


function Home () {
  const [showOne, setShowOne] = useState( false );
  const [showTwo, setShowTwo] = useState( false );
  const [showThree, setShowThree] = useState( false );
  const [showFour, setShowFour] = useState( false );
  const [showFive, setShowFive] = useState( false );

  const handleCloseModalOne = () => setShowOne( false );
  const handleShowModalOne = () => setShowOne( true );
  const handleCloseModalTwo = () => setShowTwo( false );
  const handleShowModalTwo = () => setShowTwo( true );
  const handleCloseModalThree = () => setShowThree( false );
  const handleShowModalThree = () => setShowThree( true );
  const handleShowModalFour = () => setShowFour( true );
  const handleCloseModalFour = () => setShowFour( false );
  const handleShowModalFive = () => setShowFive( true );
  const handleCloseModalFive = () => setShowFive( false );
  document.addEventListener( 'scroll', function () {
    let scrollPosition = window.pageYOffset;

    let textHome = document.querySelector( '.text-home-section-two' );
    let secondText = document.querySelector( '.second-text-section-two' );

    let parallaxSpeedTextHome = 0.3;
    let parallaxSpeedSecondText = 0.3;

    textHome.style.transform = 'translateY(' + scrollPosition * parallaxSpeedTextHome + 'px)';
    secondText.style.transform = 'translateY(' + scrollPosition * parallaxSpeedSecondText + 'px)';
  } );
  return (
    <>
      <Navbar />
      <Container fluid className='section home-section-one'>
        <Row className=''>
          <Col className=''>
            <Row>
              <Col xs={12} sm={12} md={8} lg={5} xl={5} className='title-home-section-one'>
                <h1>Uma produtora especializada no <span>Impossível</span></h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <img className='elipse elipse-1' src={Elipse} alt="elipse-woman-1" />
              </Col>
              <Col>
                <img className='elipse elipse-2' src={Elipse} alt="elipse-woman-1" />
              </Col>
              <Col>
                <img className='elipse elipse-3' src={Elipse} alt="elipse-woman-1" />
              </Col>
              <Col>
                <img className='elipse elipse-4' src={Elipse} alt="elipse-woman-1" />
              </Col>
              <Col>
                <img className='elipse elipse-5' src={Elipse} alt="elipse-woman-1" />
              </Col>
              <Col>
                <img className='elipse elipse-6' src={Elipse} alt="elipse-woman-1" />
              </Col>
              <Col>
                <img className='elipse elipse-7' src={Elipse} alt="elipse-woman-1" />
              </Col>
            </Row>
            <Row>
              <Col className='image-woman'>
                <video src={ElipseVideo} autoPlay loop muted playsInline  />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className='button-home-section-one'>
            <Button className="rounded-0 button-nsi" variant="outline-light" onClick={handleShowModalOne}>ASSISTIR O REEL</Button>
          </Col>
        </Row>
        <Modal show={showOne} onHide={handleCloseModalOne} fullscreen>
          <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
            <Button variant="link" className="text-white position-absolute top-0 end-0 mt-2 me-2" onClick={handleCloseModalOne}>
              <FaTimes size={30} />
            </Button>
            <div style={{ width: '80%', height: '80%' }}>
              <iframe
                className="w-100 h-100"
                src="https://player.vimeo.com/video/762055076?autoplay=1&color=ff0179"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Reel Video"
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Container fluid className='section home-section-two'>
        <Row>
          <Col className='text-home-section-two' lg={{ span: 10, offset: 1 }}>
            <p>Nascemos para realizar filmes publicitários e projetos de inovação que carregam grandes desafios.</p>
          </Col>
          <Col className='second-text-section-two text-home-section-two' lg={{ span: 10, offset: 1 }}>
            <p>Nos guiamos pelo impossível - e assim idealizamos soluções tecnológicas, criativas e operacionais, sempre a favor da narrativa.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 6, offset: 6 }} sm={{ span: 6, offset: 6 }} md={{ span: 6, offset: 6 }} lg={{ span: 3, offset: 9 }} xl={{ span: 3, offset: 9 }} className='camaro-home-section-two'>
            <Image src={ImgOne} fluid />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6, offset: 6 }} lg={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }} className='mt-5 dress-home-section-two'>
            <Image src={ImgTwo} />
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 6, offset: 3 }} className='studio-home-section-two'>
            <Image src={ImgThree} fluid />
          </Col>
        </Row>
      </Container>
      <Container fluid className='section home-section-three' >
        <Row>
          <Col className='title-home-section-three'>
            <p>NOSSOS <span>TRABALHOS</span></p>
          </Col>
        </Row>
        <Row>
          <Col className='desk' xs={12} sm={12} md={12} lg={6}>
            <Image src={NossoTrabalho} fluid />
          </Col>
          <Col xs={12} sm={12} className='mobile'>
            <Image src={NossoTrabalhoMob} fluid />
          </Col>
          <Col className='text-home-section-three'>
            <h2>
              Pensamos através do Craft, Design e Tecnologia. Seja nos Filmes, nos Projetos de Inovação ou nas Experiências Imersivas.
            </h2>
            <p className='text-description-home'>
              O resultado de tudo isso são projetos que fogem do óbvio, além de prêmios e indicações que vão de Cannes ao Grammy Latino - e nosso nome no Guinness Book.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className='section home-section-four pb-4'>
        <Row>
          <Col md={8} lg={8} className='desk'>
            <Row>
              <Col>
                <span className='label'>FILMES</span>
                <h2 className='title-home-section-four'>XP<br /> Investimentos,<br /> Fator XP</h2>
              </Col>
              <Col>
                <Image src={Predio} fluid />
              </Col>
            </Row>
            <Row>
              <Col className='desk text-home-section-four text-description-section-four'>
                <p >
                  Uma imersão com os top 100 assessores de todo o Brasil no HQ da XP em São Paulo e o prazer desafiador de trabalhar com não-atores. Pessoas reais, histórias reais.
                </p>
                <Button className="button-nsi border border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalTwo}>
                  <span>ASSISTIR</span>
                </Button>
              </Col>
              <Col>
                <Image src={PredioDois} fluid />
              </Col>
            </Row>
          </Col>
          <Modal show={showTwo} onHide={handleCloseModalTwo} fullscreen>
            <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
              <Button variant="link" className="text-white position-absolute top-0 end-0 mt-2 me-2" onClick={handleCloseModalTwo}>
                <FaTimes size={30} />
              </Button>
              <div style={{ width: '80%', height: '80%' }}>
                <iframe
                  className="w-100 h-100"
                  src="https://player.vimeo.com/video/906042993?autoplay=1&color=ff0179"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Reel Video"
                ></iframe>
              </div>
            </Modal.Body>
          </Modal>
          <Col className='image-home-section-four desk'>
            <Image className='image-man-home-section-four' src={Homem} fluid />
          </Col>
        </Row>
        <Row>
          <Col className=' label-section-four mobile'>
            <span className='label'>FILMES</span>
            <h2 className='title-home-section-four mt-4'>XP Investimentos, Fator XP</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className='mobile'>
            <Row>
              <Col className='build-man-home'>
                <Image src={Predio} fluid />
              </Col>
            </Row>
            <Row>
              <Col className='build-man-home'>
                <Image src={PredioDois} fluid />
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} className='man-section-four mobile'>
            <Image src={HomemMobile} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='text-home-section-four mobile'>
            <p>
              Uma imersão com os top 100 assessores de todo o Brasil no HQ da XP em São Paulo e o prazer desafiador de trabalhar com não-atores. Pessoas reais, histórias reais.
            </p>
            <Button className="button-nsi border border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalTwo}>
              <span>ASSISTIR</span>
            </Button>{' '}
          </Col>
        </Row>
      </Container>
      <div className="line" />
      <Container fluid className='section home-section-five pt-5 pb-4'>
        <Row>
          <Col className='desk' lg={8} xl={8}>
            <Image className="" src={PredioInovacao} fluid />
          </Col>
          <Col className='desk'>
            <span className='label label-home'>EXPERIÊNCIAS</span>
            <h2 className='title-home-section-five'>Parque Global,<br /> Showroom<br /> Experience</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={8} xl={8} className='mt-4 desk'>
            <Image className="" src={Stephen} fluid />
          </Col>
          <Col className='mt-4 text-home-section-five desk'>
            <p>
              Uma narrativa emocional e impactante pensada para um mergulho total no projeto. Instalações Digitais que provocam um estímulo completo, para transformar uma simples visita em um espetáculo único.
            </p>
            <div >
              <Button className="button-nsi border button-square border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalThree}>
                <span>ASSISTIR</span>
              </Button>{' '}
            </div>
          </Col>
        </Row>
        <Modal show={showThree} onHide={handleCloseModalThree} fullscreen>
          <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
            <Button variant="link" className="text-white position-absolute top-0 end-0 mt-2 me-2" onClick={handleCloseModalThree}>
              <FaTimes size={30} />
            </Button>
            <div style={{ width: '80%', height: '80%' }}>
              <iframe
                className="w-100 h-100"
                src="https://player.vimeo.com/video/491949754?autoplay=1&color=ff0179"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Reel Video"
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
        <Row>
          <Col className='label-home-col mobile'>
            <span className='label label-home'>EXPERIÊNCIAS</span>
            <h2 className='title-home-section-five'>Parque Global, Showroom Experience</h2>
          </Col>
        </Row>
        <Row>
          <Col className='pt-4 mobile'>
            <Image className="build-inovation" src={PredioInovacao} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='mobile'>
            <Image className="" src={Stephen} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='text-home-second-description mobile'>
            <p className='text-home-section-five'>
              Uma narrativa emocional e impactante pensada para um mergulho total no projeto. Instalações digitais que provocam um estímulo completo, para transformar uma simples visita em um espetáculo.
            </p>
          </Col>
        </Row>
        <Row className=''>
          <Col className='button-home mobile'>
            <div >
              <Button className="button-nsi border button-square border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalThree}>
                <span>ASSISTIR</span>
              </Button>{' '}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="line" />
      <Container fluid className='section home-section-six pt-5 pb-4'>
        <Row>
          <Col className='desk' lg={4} xl={4}>
            <span className='label'>FILMES</span>
            <h2 className='title-home-section-six'>
              Schweppes,<br /> Dress for<br /> Respect
            </h2>
            <p className='text-home-section-six'>
              Aliar tecnologia a conscientização. Para documentar o assédio que as mulheres enfrentam em festas, a NSI e a Ogilvy criaram um vestido com sensores ultra-sensíveis que registram cada toque recebido. Vencedor Glass Lion em Cannes 2018.
            </p>
            <div >
              <Button className="button-nsi border button-square border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalFour}>
                <span>ASSISTIR</span>
              </Button>{' '}
            </div>
          </Col>
          <Modal show={showFour} onHide={handleCloseModalFour} fullscreen>
            <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
              <Button variant="link" className="text-white position-absolute top-0 end-0 mt-2 me-2" onClick={handleCloseModalFour}>
                <FaTimes size={30} />
              </Button>
              <div style={{ width: '80%', height: '80%' }}>
                <iframe
                  className="w-100 h-100"
                  src="https://player.vimeo.com/video/272241191?autoplay=1&color=ff0179"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Reel Video"
                ></iframe>
              </div>
            </Modal.Body>
          </Modal>
          <Col className='desk'>
            <Image className="" src={Dress} fluid />
          </Col>
          <Col className='desk'>
            <Image className="" src={BodyImg} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='label-home-col mobile'>
            <span className='label label-home'>
              FILMES
            </span>
            <h2 className='title-home-section-six'>
              Dress for Respect, Schweppes, Coca-Cola
            </h2>
          </Col>
        </Row>
        <Row className='images-dress'>
          <Col className='mobile'>
            <Image className="" src={Dress} fluid />
          </Col>
          <Col className='mobile'>
            <Image className="" src={BodyImg} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='text-home-second-description mobile'>
            <p className='text-home-section-six'>
              Para conscientizar sobre o assédio que as mulheres enfrentam quando vão a festas, a NSI e a Ogilvy criaram um vestido com tecido e sensores ultra-sensíveis que registram cada toque recebido. Vencedor Glass Lion em Cannes 2018.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='button-home mobile'>
            <div >
              <Button className="button-nsi  border button-square border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalFour}>
                <span>ASSISTIR</span>
              </Button>{' '}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="line" />
      <Container fluid className='section home-section-seven pt-5'>
        <Row>
          <Col className='desk'>
            <span className='label label-home'>FILMES</span>
            <h2 className='title-home-section-seven mt-3'>
              McDonald's,<br /> ASMR
            </h2>
          </Col>
          <Col className='desk'>
            <Image className="" src={FoodOne} fluid />
          </Col>
          <Col className='desk'>
            <Image className="" src={FoodThree} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='desk'>
            <p className='text-home-section-seven'>
              Um filme que desperta o desejo através da experiência sonora única e deliciosa do ASMR. O som nunca foi tão delicioso e a gente pode provar.
            </p>
            <div >
              <Button className="button-nsi border button-square border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalFive}>
                <span>ASSISTIR</span>
              </Button>{' '}
            </div>
          </Col>
          <Modal show={showFive} onHide={handleCloseModalFive} fullscreen>
            <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
              <Button variant="link" className="text-white position-absolute top-0 end-0 mt-2 me-2" onClick={handleCloseModalFive}>
                <FaTimes size={30} />
              </Button>
              <div style={{ width: '80%', height: '80%' }}>
                <iframe
                  className="w-100 h-100"
                  src="https://player.vimeo.com/video/731207621?autoplay=1&color=ff0179"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Reel Video"
                ></iframe>
              </div>
            </Modal.Body>
          </Modal>
          <Col className='desk'>
            <Image className="" src={FoodTwo} fluid />
          </Col>
          <Col className='desk'>
            <Image className="" src={FoodFour} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='title-home-section-seven mobile'>
            <span className='label label-home'>
              FILMES
            </span>
            <h2 className='mt-3'>
              McDonald's, ASMR
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className='mobile'>
            <Image className="" src={FoodOne} fluid />
          </Col>
          <Col className='mobile'>
            <Image className="" src={FoodThree} fluid />
          </Col>
        </Row>
        <Row           >
          <Col className='mobile'>
            <Image className="" src={FoodTwo} fluid />
          </Col>
          <Col className='mobile'>
            <Image className="" src={FoodFour} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='mobile'>
            <p className='text-home-section-seven'>
              Um filme que desperta o desejo através da experiência sonora única e deliciosa do ASMR. O som nunca foi tão delicioso e a gente pode provar.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className='button-home mobile'>
            <div >
              <Button className="button-nsi border button-square border-dark border-radius-3 rounded-0" variant="outline-dark" onClick={handleShowModalFive}>
                <span>ASSISTIR</span>
              </Button>{' '}
            </div>
          </Col>
        </Row>
        <Row>
          <Col >
            <p className='see-impossible-home mobile'>
              Veja o que mais fizemos
              <br />
              de impossível:
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='mobile'>
            <a href='/filmes'>
              <Button className="button-nsi  button-square border  border-dark border-radius-3 rounded-0" variant="outline-dark">
                <span>Filmes</span>
              </Button>
            </a>
            <a href='/experiencias'>
              <Button className="button-nsi see-impossible-home-buttons button-square border border-dark border-radius-3 rounded-0" variant="outline-dark">
                <span>Experiências</span>
              </Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 4 }} className='desk' >
            <p className='text-medium-fraunces-2 text-home see-impossible-home desk'>
              Veja tudo que fizemos
              <br />
              de impossível:
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 4 }} className='desk'>
            <a href='/filmes'>
              <Button className="button-nsi  button-square  border  border-dark border-radius-3 rounded-0" variant="outline-dark">
                <span>Nossos Filmes</span>
              </Button>
            </a>
            <a href='/experiencias'>
              <Button className="button-nsi see-impossible-home-buttons button-square  border border-dark border-radius-3 rounded-0" variant="outline-dark">
                <span>Nossas Experiências</span>
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
